import React from "react"
// mui
import {Paper} from "@mui/material";
// components
import {SectionCollapse} from "../../index";

export default function Biography(props: any) {

    const {styles, t, vcardData, preview, plain, minimalist, template, templateData} = props;

    if (vcardData === undefined || vcardData === null) {
        return null
    }

    if (vcardData.information === undefined || vcardData.information === null) {
        return null
    }

    if (vcardData.information.biography === "") {
        return null
    }

    if (preview || vcardData.information.biography) {
        if (minimalist) {
            return (
                <Paper sx={styles.paper}>
                    <div style={styles.text} dangerouslySetInnerHTML={{__html: vcardData.information.biography}}></div>
                </Paper>
            )
        } else {
            return (
                <Paper sx={styles.paper}>
                    <SectionCollapse
                        type={"biography"}
                        title={
                            !!template?.templateOption?.biographyTitle ? template.templateOption.biographyTitle
                                : templateData.templateType === "PRODUCT" ? t( "product_biography")
                                    : t("vcard_biography")
                        }
                        templateType={templateData.templateType}
                        {...{styles, plain, t}}
                    >
                            <div style={styles.text} dangerouslySetInnerHTML={{__html: vcardData.information.biography}}></div>
                    </SectionCollapse>
                </Paper>
            )
        }
    }

    return <></>
}
