import React from "react"
import {Box, Button} from "@mui/material";

export default function FloatingButtons(props: any) {

    const {
        styles,
        t,
        downloadVcf,
    } = props;

    return (
        <>
            <Box>
                <Button
                    fullWidth
                    size={"large"}
                    sx={{...styles.buttons, ...styles.floatingAddMeButton}}
                    onClick={downloadVcf}
                >
                    {t("vcard_add_me")}
                </Button>
            </Box>
        </>
    )
}